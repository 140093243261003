<template>
  <default-layout>
    <div class="mx-auto flex max-w-7xl flex-1 flex-col">
      <h1 class="mb-6 font-display text-4xl">
        {{ title }}
      </h1>

      <form
        class="flex w-full max-w-xs flex-col flex-wrap justify-between space-y-2 md:max-w-none lg:flex-row lg:flex-nowrap lg:justify-start lg:gap-x-4 lg:space-y-0 lg:space-x-2"
        @submit.prevent="submit"
      >
        <div
          class="flex w-full flex-col flex-wrap space-y-2 md:flex-row md:gap-x-2 md:space-y-0"
        >
          <div
            v-if="showJournal"
            class="inline-flex items-center space-x-1 rounded border bg-white bg-opacity-80 px-2 py-1"
          >
            <text-button
              v-tooltip="'Any status'"
              size="sm"
              type="button"
              @click="(status = null), submit()"
            >
              <fv-icon
                :class="status === null ? 'text-primary' : ''"
                icon="chevron-down"
              />
            </text-button>

            <text-button
              v-tooltip="'Show only unsigned'"
              size="sm"
              type="button"
              data-testid="show-unsigned"
              @click="(status = 0), submit()"
            >
              <fv-icon
                :class="status === 0 ? 'text-primary' : ''"
                icon="warning"
                size="md"
              />
            </text-button>
            <text-button
              v-tooltip="'Show only signed'"
              size="sm"
              type="button"
              data-testid="show-signed"
              @click="(status = 1), submit()"
            >
              <fv-icon
                :class="status === 1 ? 'text-primary' : ''"
                icon="check-mark-alt"
                size="md"
              />
            </text-button>
          </div>

          <datepicker
            ref="historyDatePicker"
            :value="selectedDate"
            input-class="flex flex-1 font-semibold bg-white
          border rounded cursor-pointer focus:outline-none py-0 h-10"
            wrapper-class=""
            calendar-class="font-normal rounded shadow uppercase text-sm left-auto"
            :monday-first="true"
            minimum-view="month"
            maximum-view="month"
            placeholder="Select a month"
            :disabled-dates="disabledDates"
            :format="customFormatter"
            @input="setDatesAndFetch($event)"
            @cleared="setDates($event)"
          />
          <div class="inline-flex w-full flex-1 items-center space-x-2">
            <base-input
              ref="searchBox"
              v-model="searchstr"
              class="flex-1"
              placeholder="Search by keyword"
            />
            <div class="inline-flex flex-1 space-x-1">
              <base-button type="submit" color="dark" class="w-full">
                <fv-icon icon="search" />
                <span class="ml-2 inline-flex">Search</span>
              </base-button>

              <base-button
                v-if="payload"
                v-tooltip="'Reset filters'"
                type="button"
                role="button"
                color="cancel"
                @click="resetFilters"
              >
                <fv-icon icon="switch" class="h-4 w-4" />
              </base-button>
            </div>
          </div>
        </div>
      </form>

      <!-- <form
        class="flex w-full items-baseline"
        @submit.prevent="submit"
      >
        <div
          class="w-1/3 flex items-center space-x-2"
        >
          <v-select
            label="name"
            :reduce="opt => opt.id"
            class="max-w-xs w-1/2"
            :value="status"
            :options="statusOptions"
            @input="status = $event, submit()"
          />

          <datepicker
            ref="historyDatePicker"
            :value="selectedDate"
            input-class="flex w-1/2 font-semibold bg-white
          border rounded cursor-pointer focus:outline-none py-0"
            wrapper-class=""
            calendar-class="font-normal rounded shadow uppercase text-sm left-auto"
            :monday-first="true"
            minimum-view="month"
            maximum-view="month"
            placeholder="Select a month"
            :disabled-dates="disabledDates"
            :format="customFormatter"
            @input="setDatesAndFetch($event)"
            @cleared="setDates($event)"
          />
        </div>
        <div
          class="w-2/3 flex items-center space-x-1"
        >
          <base-input
            ref="searchBox"
            v-model="searchstr"
            class="flex-1"
            placeholder="Search for keyword"
          />
          <div class="inline-flex items-center space-x-1 flex-1">
            <base-button
              color="dark"
              :disabled="loading"
              type="submit"
              class="w-full"
            >
              <fv-icon
                icon="search"
              />
              <span class="inline-flex ml-2">Search</span>
            </base-button>
            <base-button
              v-if="payload"
              color="cancel"
              type="reset"
              role="reset"
              @click="resetFilters"
            >
              <fv-icon
                icon="switch"
              />
            </base-button>
          </div>
        </div>
      </form> -->

      <table-container style="min-height: 50vh" :theads="theads" class="">
        <spinner-overlay size="xl" :loading="loading" />
        <tr
          v-for="appointment in list.data"
          :key="appointment.id"
          class="text-left text-sm hover:bg-beigeLighter"
          data-testid="appointment-list-item"
        >
          <table-data>
            <div class="flex flex-col space-y-1 text-gray-700">
              <span>{{ appointment.id }}</span>
              <consultation-type-badge :type="getServiceChannel(appointment)" />
            </div>
          </table-data>
          <table-data>
            <div class="inline-flex flex-col space-y-2">
              <div class="inline-flex space-x-1">
                <span>
                  {{ $formatDate(appointment.booking.booking_datetime) }}
                </span>
                <span>
                  {{ $formatTime(appointment.booking.booking_datetime) }}
                </span>
              </div>

              <div
                v-if="appointment.appointment_ratings"
                class="flex items-center space-x-1"
              >
                <p v-if="!appointment.appointment_ratings.rate" class="text-sm">
                  Decided not to leave a rating.
                </p>
                <div
                  v-if="appointment.appointment_ratings.rate"
                  class="inline-flex"
                >
                  <fv-icon
                    v-for="n in 5"
                    :key="n"
                    class="h-5 w-5"
                    :class="
                      n <= appointment.appointment_ratings.rate
                        ? 'text-yellow-600'
                        : 'text-gray-500'
                    "
                    :icon="
                      n <= appointment.appointment_ratings.rate
                        ? 'star-selected'
                        : 'star'
                    "
                  />
                </div>

                <div v-if="appointment.appointment_ratings.comment">
                  <fv-icon
                    v-tooltip.right="{
                      content: appointment.appointment_ratings.comment,
                      classes: 'history-tooltip',
                    }"
                    class=""
                    icon="comments"
                  />
                </div>
              </div>
            </div>
          </table-data>
          <table-data style="max-width: 15rem">
            <div class="flex flex-col space-y-1">
              <p class="truncate">
                {{ appointment.user.display_name }}
              </p>
              <a
                :href="'mailto:' + appointment.user.email"
                class="truncate text-sm"
              >
                {{ appointment.user.email }}
              </a>
            </div>
          </table-data>
          <table-data>
            <div
              class="inline-flex flex-col space-y-1"
              style="max-width: 14rem"
            >
              <p class="">
                <span>{{ appointment.animal.name }}</span>

                <span />
              </p>
              <p class="inline-flex space-x-1">
                <span>
                  {{
                    appointment.animal.animal_type
                      ? appointment.animal.animal_type.name
                      : '-'
                  }}
                </span>
                <span v-if="appointment.animal.race" class="truncate">
                  - {{ appointment.animal.race }}
                </span>
              </p>
            </div>
          </table-data>
          <table-data v-if="showJournal">
            <div
              v-if="appointment.journal"
              class="inline-flex flex-col space-y-2"
            >
              <div
                class="inline-flex items-center space-x-1"
                data-testid="journal-status"
              >
                <fv-icon
                  class="h-4 w-4"
                  :icon="
                    appointment.journal.status === 1 ? 'check-mark' : 'warning'
                  "
                  :class="
                    appointment.journal.status === 1
                      ? 'text-green-800'
                      : 'text-yellow-800'
                  "
                  color="#ffffff"
                />
                <span v-if="!appointment.journal.status">Not signed</span>
                <text-button
                  v-if="appointment.journal.status"
                  size="sm"
                  color="dark"
                  @click="
                    (viewAppointment = appointment),
                      setJournalComments(appointment.journal.comments),
                      fetchProductRecommendations(appointment.journal.id)
                  "
                >
                  <span class="inline-flex items-center space-x-2">
                    <span>Signed {{ appointment.journal.notified }}</span>
                  </span>
                </text-button>
              </div>
              <p class="text-gray-700">
                {{
                  appointment.appointment_type
                    ? appointment.appointment_type.name
                    : '-'
                }}
              </p>
            </div>
          </table-data>
          <table-data>
            <text-button
              :key="appointment.id"
              color="primary"
              class="rounded border px-2 py-2"
              :to="'/vetpanel/appointment/' + appointment.id"
              data-testid="go-to-room-button"
            >
              <span>Go to room</span>
              <fv-icon icon="chevron-right" class="ml-2" />
            </text-button>
          </table-data>
        </tr>
        <div
          slot="tableFooter"
          class="flex w-full items-baseline justify-between"
        >
          <pagination-buttons
            :pagination-list="list"
            :loading="loading"
            @previous="fetchmore(list.prev_page_url)"
            @next="fetchmore(list.next_page_url)"
          />
          <div>Viewing {{ list.data.length }} of {{ list.total }} entries</div>
        </div>
      </table-container>
    </div>

    <base-modal
      v-if="viewAppointment"
      :show="!!viewAppointment"
      @close="(viewAppointment = null), (productRecommendations = [])"
    >
      <slot>
        <Journal
          :journal="viewAppointment.journal"
          :appointment-type="viewAppointment.appointment_type"
          :user-name="viewAppointment.booking.veterinary.display_name"
          :user-avatar="viewAppointment.booking.veterinary.avatar"
          :display-post-comment-section="false"
          :product-recommendations="productRecommendations"
        />
      </slot>
      <div slot="buttons" class="flex w-full justify-end space-x-2 p-2">
        <base-button
          color="cancel"
          @click="
            (viewAppointment = null),
              setJournalComments([]),
              (productRecommendations = [])
          "
        >
          Close
        </base-button>
      </div>
    </base-modal>
  </default-layout>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { OrdersApi, EcomApi } from '@/api';
import '@/utils/helpers/time-helpers';
import Datepicker from 'vuejs-datepicker';
import ConsultationTypeBadge from '@/UI/badges/ConsultationTypeBadge.vue';
import Journal from '@/components/journal/Journal';
import PaginationButtons from '@/UI/PaginationButtons';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export default {
  components: {
    PaginationButtons,
    Datepicker,
    Journal,
    ConsultationTypeBadge,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    showJournal: {
      type: Boolean,
      default: true,
    },
    ratings: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      viewAppointment: null,
      selectedDate: null,
      loading: false,
      list: {
        data: [],
      },
      year: null,
      month: null,
      theads: this.showJournal
        ? ['ID', 'Booking', 'Customer', 'Animal', 'Journal', '']
        : ['ID', 'Booking', 'Customer', 'Animal', ''],
      searchstr: '',
      payload: null,

      statusOptions: [
        {
          id: null,
          name: 'All',
        },
        {
          id: 0,
          name: 'Unsigned',
        },
        {
          id: 1,
          name: 'Signed',
        },
      ],
      status: null,

      productRecommendations: [],
    };
  },

  computed: {
    ...mapGetters({ locale: 'getLocale' }),
    ...mapState('admin', ['countryId']),
    ...mapGetters('journal', ['showProductRecommendations']),

    customFormatter() {
      return format(new Date(), 'yyyy-MM');
    },
    disabledDates() {
      return {
        from: new Date(),
      };
    },
  },

  mounted() {
    console.log(this.ratings);
    this.fetch();
  },

  methods: {
    ...mapMutations('journal', ['setJournalComments']),
    async submit() {
      this.loading = true;
      this.payload = {
        month: this.month === null ? null : this.month + 1,
        year: this.year,
        status: this.status,
        search: this.searchstr.toLowerCase(),
      };
      if (this.ratings) this.payload.ratings = this.ratings;
      try {
        const { data } = await OrdersApi.query(this.payload, this.locale);
        this.list = data.appointments;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async fetch() {
      this.loading = true;
      this.payload = null;
      if (this.ratings) {
        this.payload = { ratings: this.ratings };
      }

      const { data } = await OrdersApi.fetchAll(this.payload, this.locale);
      this.list = data.appointments;
      this.formatTime(this.list.data);
      this.loading = false;
    },
    fetchmore(url) {
      if (url) {
        this.loading = true;
        OrdersApi.fetchMore(url, this.locale)
          .then(res => {
            this.list = res.data.appointments;
            this.formatTime(this.list.data);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    formatTime(appointments) {
      appointments.map(data => {
        const appointment = { ...data };
        appointment.booking.booking_datetime = format(
          zonedTimeToUtc(
            appointment.booking.booking_datetime,
            appointment.booking.timezone
          ),
          'yyyy-MM-dd HH:mm'
        );
        return appointment;
      });
    },
    resetFilters() {
      this.$refs.historyDatePicker.clearDate();
      this.searchstr = '';
      this.status = null;
      this.payload = null;
      this.fetch();
    },
    setDatesAndFetch(dateTime) {
      this.setDates(dateTime);
      this.submit();
    },
    setDates(dateTime) {
      if (!dateTime) {
        this.selectedDate = null;
        this.month = null;
        this.year = null;
      } else {
        this.selectedDate = dateTime;
        this.month = dateTime.getMonth();
        this.year = dateTime.getFullYear();
      }
    },
    getServiceChannel(appointment) {
      return appointment?.booking?.service?.key === 'veterinary_chat'
        ? 'chat'
        : 'video';
    },
    async fetchProductRecommendations(journalId) {
      if (this.showProductRecommendations && journalId) {
        try {
          const { data } = await EcomApi.getProductRecommendations({
            journalId,
            countryId: this.countryId,
          });

          this.productRecommendations = data;
        } catch (error) {
          this.productRecommendations = [];
        }
      }
    },
  },
};
</script>
