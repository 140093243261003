var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "default-layout",
    [
      _c(
        "div",
        { staticClass: "mx-auto flex max-w-7xl flex-1 flex-col" },
        [
          _c("h1", { staticClass: "mb-6 font-display text-4xl" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
          _c(
            "form",
            {
              staticClass:
                "flex w-full max-w-xs flex-col flex-wrap justify-between space-y-2 md:max-w-none lg:flex-row lg:flex-nowrap lg:justify-start lg:gap-x-4 lg:space-y-0 lg:space-x-2",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex w-full flex-col flex-wrap space-y-2 md:flex-row md:gap-x-2 md:space-y-0",
                },
                [
                  _vm.showJournal
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "inline-flex items-center space-x-1 rounded border bg-white bg-opacity-80 px-2 py-1",
                        },
                        [
                          _c(
                            "text-button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: "Any status",
                                  expression: "'Any status'",
                                },
                              ],
                              attrs: { size: "sm", type: "button" },
                              on: {
                                click: function ($event) {
                                  ;(_vm.status = null), _vm.submit()
                                },
                              },
                            },
                            [
                              _c("fv-icon", {
                                class:
                                  _vm.status === null ? "text-primary" : "",
                                attrs: { icon: "chevron-down" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "text-button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: "Show only unsigned",
                                  expression: "'Show only unsigned'",
                                },
                              ],
                              attrs: {
                                size: "sm",
                                type: "button",
                                "data-testid": "show-unsigned",
                              },
                              on: {
                                click: function ($event) {
                                  ;(_vm.status = 0), _vm.submit()
                                },
                              },
                            },
                            [
                              _c("fv-icon", {
                                class: _vm.status === 0 ? "text-primary" : "",
                                attrs: { icon: "warning", size: "md" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "text-button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: "Show only signed",
                                  expression: "'Show only signed'",
                                },
                              ],
                              attrs: {
                                size: "sm",
                                type: "button",
                                "data-testid": "show-signed",
                              },
                              on: {
                                click: function ($event) {
                                  ;(_vm.status = 1), _vm.submit()
                                },
                              },
                            },
                            [
                              _c("fv-icon", {
                                class: _vm.status === 1 ? "text-primary" : "",
                                attrs: { icon: "check-mark-alt", size: "md" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("datepicker", {
                    ref: "historyDatePicker",
                    attrs: {
                      value: _vm.selectedDate,
                      "input-class":
                        "flex flex-1 font-semibold bg-white\n        border rounded cursor-pointer focus:outline-none py-0 h-10",
                      "wrapper-class": "",
                      "calendar-class":
                        "font-normal rounded shadow uppercase text-sm left-auto",
                      "monday-first": true,
                      "minimum-view": "month",
                      "maximum-view": "month",
                      placeholder: "Select a month",
                      "disabled-dates": _vm.disabledDates,
                      format: _vm.customFormatter,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.setDatesAndFetch($event)
                      },
                      cleared: function ($event) {
                        return _vm.setDates($event)
                      },
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "inline-flex w-full flex-1 items-center space-x-2",
                    },
                    [
                      _c("base-input", {
                        ref: "searchBox",
                        staticClass: "flex-1",
                        attrs: { placeholder: "Search by keyword" },
                        model: {
                          value: _vm.searchstr,
                          callback: function ($$v) {
                            _vm.searchstr = $$v
                          },
                          expression: "searchstr",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "inline-flex flex-1 space-x-1" },
                        [
                          _c(
                            "base-button",
                            {
                              staticClass: "w-full",
                              attrs: { type: "submit", color: "dark" },
                            },
                            [
                              _c("fv-icon", { attrs: { icon: "search" } }),
                              _c("span", { staticClass: "ml-2 inline-flex" }, [
                                _vm._v("Search"),
                              ]),
                            ],
                            1
                          ),
                          _vm.payload
                            ? _c(
                                "base-button",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: "Reset filters",
                                      expression: "'Reset filters'",
                                    },
                                  ],
                                  attrs: {
                                    type: "button",
                                    role: "button",
                                    color: "cancel",
                                  },
                                  on: { click: _vm.resetFilters },
                                },
                                [
                                  _c("fv-icon", {
                                    staticClass: "h-4 w-4",
                                    attrs: { icon: "switch" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "table-container",
            {
              staticStyle: { "min-height": "50vh" },
              attrs: { theads: _vm.theads },
            },
            [
              _c("spinner-overlay", {
                attrs: { size: "xl", loading: _vm.loading },
              }),
              _vm._l(_vm.list.data, function (appointment) {
                return _c(
                  "tr",
                  {
                    key: appointment.id,
                    staticClass: "text-left text-sm hover:bg-beigeLighter",
                    attrs: { "data-testid": "appointment-list-item" },
                  },
                  [
                    _c("table-data", [
                      _c(
                        "div",
                        {
                          staticClass: "flex flex-col space-y-1 text-gray-700",
                        },
                        [
                          _c("span", [_vm._v(_vm._s(appointment.id))]),
                          _c("consultation-type-badge", {
                            attrs: { type: _vm.getServiceChannel(appointment) },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("table-data", [
                      _c(
                        "div",
                        { staticClass: "inline-flex flex-col space-y-2" },
                        [
                          _c("div", { staticClass: "inline-flex space-x-1" }, [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatDate(
                                      appointment.booking.booking_datetime
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatTime(
                                      appointment.booking.booking_datetime
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          appointment.appointment_ratings
                            ? _c(
                                "div",
                                { staticClass: "flex items-center space-x-1" },
                                [
                                  !appointment.appointment_ratings.rate
                                    ? _c("p", { staticClass: "text-sm" }, [
                                        _vm._v(
                                          " Decided not to leave a rating. "
                                        ),
                                      ])
                                    : _vm._e(),
                                  appointment.appointment_ratings.rate
                                    ? _c(
                                        "div",
                                        { staticClass: "inline-flex" },
                                        _vm._l(5, function (n) {
                                          return _c("fv-icon", {
                                            key: n,
                                            staticClass: "h-5 w-5",
                                            class:
                                              n <=
                                              appointment.appointment_ratings
                                                .rate
                                                ? "text-yellow-600"
                                                : "text-gray-500",
                                            attrs: {
                                              icon:
                                                n <=
                                                appointment.appointment_ratings
                                                  .rate
                                                  ? "star-selected"
                                                  : "star",
                                            },
                                          })
                                        }),
                                        1
                                      )
                                    : _vm._e(),
                                  appointment.appointment_ratings.comment
                                    ? _c(
                                        "div",
                                        [
                                          _c("fv-icon", {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip.right",
                                                value: {
                                                  content:
                                                    appointment
                                                      .appointment_ratings
                                                      .comment,
                                                  classes: "history-tooltip",
                                                },
                                                expression:
                                                  "{\n                    content: appointment.appointment_ratings.comment,\n                    classes: 'history-tooltip',\n                  }",
                                                modifiers: { right: true },
                                              },
                                            ],
                                            attrs: { icon: "comments" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _c(
                      "table-data",
                      { staticStyle: { "max-width": "15rem" } },
                      [
                        _c("div", { staticClass: "flex flex-col space-y-1" }, [
                          _c("p", { staticClass: "truncate" }, [
                            _vm._v(
                              " " + _vm._s(appointment.user.display_name) + " "
                            ),
                          ]),
                          _c(
                            "a",
                            {
                              staticClass: "truncate text-sm",
                              attrs: {
                                href: "mailto:" + appointment.user.email,
                              },
                            },
                            [_vm._v(" " + _vm._s(appointment.user.email) + " ")]
                          ),
                        ]),
                      ]
                    ),
                    _c("table-data", [
                      _c(
                        "div",
                        {
                          staticClass: "inline-flex flex-col space-y-1",
                          staticStyle: { "max-width": "14rem" },
                        },
                        [
                          _c("p", {}, [
                            _c("span", [
                              _vm._v(_vm._s(appointment.animal.name)),
                            ]),
                            _c("span"),
                          ]),
                          _c("p", { staticClass: "inline-flex space-x-1" }, [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    appointment.animal.animal_type
                                      ? appointment.animal.animal_type.name
                                      : "-"
                                  ) +
                                  " "
                              ),
                            ]),
                            appointment.animal.race
                              ? _c("span", { staticClass: "truncate" }, [
                                  _vm._v(
                                    " - " +
                                      _vm._s(appointment.animal.race) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]),
                    _vm.showJournal
                      ? _c("table-data", [
                          appointment.journal
                            ? _c(
                                "div",
                                {
                                  staticClass: "inline-flex flex-col space-y-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "inline-flex items-center space-x-1",
                                      attrs: {
                                        "data-testid": "journal-status",
                                      },
                                    },
                                    [
                                      _c("fv-icon", {
                                        staticClass: "h-4 w-4",
                                        class:
                                          appointment.journal.status === 1
                                            ? "text-green-800"
                                            : "text-yellow-800",
                                        attrs: {
                                          icon:
                                            appointment.journal.status === 1
                                              ? "check-mark"
                                              : "warning",
                                          color: "#ffffff",
                                        },
                                      }),
                                      !appointment.journal.status
                                        ? _c("span", [_vm._v("Not signed")])
                                        : _vm._e(),
                                      appointment.journal.status
                                        ? _c(
                                            "text-button",
                                            {
                                              attrs: {
                                                size: "sm",
                                                color: "dark",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  ;(_vm.viewAppointment =
                                                    appointment),
                                                    _vm.setJournalComments(
                                                      appointment.journal
                                                        .comments
                                                    ),
                                                    _vm.fetchProductRecommendations(
                                                      appointment.journal.id
                                                    )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "inline-flex items-center space-x-2",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Signed " +
                                                        _vm._s(
                                                          appointment.journal
                                                            .notified
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("p", { staticClass: "text-gray-700" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          appointment.appointment_type
                                            ? appointment.appointment_type.name
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _c(
                      "table-data",
                      [
                        _c(
                          "text-button",
                          {
                            key: appointment.id,
                            staticClass: "rounded border px-2 py-2",
                            attrs: {
                              color: "primary",
                              to: "/vetpanel/appointment/" + appointment.id,
                              "data-testid": "go-to-room-button",
                            },
                          },
                          [
                            _c("span", [_vm._v("Go to room")]),
                            _c("fv-icon", {
                              staticClass: "ml-2",
                              attrs: { icon: "chevron-right" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "div",
                {
                  staticClass: "flex w-full items-baseline justify-between",
                  attrs: { slot: "tableFooter" },
                  slot: "tableFooter",
                },
                [
                  _c("pagination-buttons", {
                    attrs: {
                      "pagination-list": _vm.list,
                      loading: _vm.loading,
                    },
                    on: {
                      previous: function ($event) {
                        return _vm.fetchmore(_vm.list.prev_page_url)
                      },
                      next: function ($event) {
                        return _vm.fetchmore(_vm.list.next_page_url)
                      },
                    },
                  }),
                  _c("div", [
                    _vm._v(
                      "Viewing " +
                        _vm._s(_vm.list.data.length) +
                        " of " +
                        _vm._s(_vm.list.total) +
                        " entries"
                    ),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm.viewAppointment
        ? _c(
            "base-modal",
            {
              attrs: { show: !!_vm.viewAppointment },
              on: {
                close: function ($event) {
                  ;(_vm.viewAppointment = null),
                    (_vm.productRecommendations = [])
                },
              },
            },
            [
              _vm._t("default", function () {
                return [
                  _c("Journal", {
                    attrs: {
                      journal: _vm.viewAppointment.journal,
                      "appointment-type": _vm.viewAppointment.appointment_type,
                      "user-name":
                        _vm.viewAppointment.booking.veterinary.display_name,
                      "user-avatar":
                        _vm.viewAppointment.booking.veterinary.avatar,
                      "display-post-comment-section": false,
                      "product-recommendations": _vm.productRecommendations,
                    },
                  }),
                ]
              }),
              _c(
                "div",
                {
                  staticClass: "flex w-full justify-end space-x-2 p-2",
                  attrs: { slot: "buttons" },
                  slot: "buttons",
                },
                [
                  _c(
                    "base-button",
                    {
                      attrs: { color: "cancel" },
                      on: {
                        click: function ($event) {
                          ;(_vm.viewAppointment = null),
                            _vm.setJournalComments([]),
                            (_vm.productRecommendations = [])
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }